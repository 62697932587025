interface Product {
  slug: string;
  type: "regular" | "special";
  color: string;
  isNew: boolean;
  text: {
    name: string;
    brand: string;
    description: string;
    age: string;
    body: string;
    ingredients: string;
    preparation: string;
  };
  image: {
    packshot: string;
    packshotThumb: string;
    table: string;
  };
  medals?: string[];
}

const getPreparation = (brand: string, tableUrl?: string, gramsPerSpoon?: string, tableHtml?: string) => {

  let html;
  if (gramsPerSpoon && tableUrl) {
    html = `
      <img class="table" src="${tableUrl}"/>
      <p>
        <strong>1 cucharada nivelada es de aprox. ${gramsPerSpoon} gramos.<br/>
        El uso de agua sin hervir, taza o recipientes no esterilizados o la preparación incorrecta del producto puede ser perjudicial para su niño.</strong>
      </p>
    `;
  } else {
    html = tableHtml;
  }

  return `
    <div class="title">
      Tabla de alimentación sugerida
    </div>
    <div class="table-holder">
      ${html}
    </div> 
    <div class="title">
      Instrucciones de<br/>
      preparación y uso
    </div>
    <div class="emphasis">
      La salud de su hijo depende de que siga cuidadosamente las instrucciones para la preparación y uso:
    </div>
    <img class="prep-img" src="/img/products/preparation-a.svg" />
    <div class="text">
      <strong>1.</strong> Lávese las manos y los utensilios rigurosamente utilizando agua limpia.<br/>
      <strong>2.</strong> Hierva el agua durante 5 minutos y deje enfriar hasta que quede tibia. En caso de no contar con esta, se podrá utilizar agua puriﬁcada a temperatura ambiente. No se debe calentar usando horno de microondas.
    </div>
    <img class="prep-img" src="/img/products/preparation-b.svg" />
    <div class="text">
      <strong>3.</strong> Justo antes de su uso, enjuague el recipiente y hierva en agua.<br/>
      <strong>4.</strong> Sirva la cantidad indicada de agua en el recipiente.
    </div>
    <img class="prep-img" src="/img/products/preparation-c.svg" />
    <div class="text">
      <strong>5.</strong> Use la cuchara que viene dentro de la lata para añadir 1 cucharada nivelada por  cada 30 ml de agua.<br/>
      <strong>6.</strong> Revuelva o agite bien hasta que el polvo se disuelva por completo.
    </div>
    <img class="prep-img" src="/img/products/preparation-d.svg" />
    <div class="text">
      <strong>7.</strong> Compruebe la temperatura y alimente a su bebé cuando la fórmula ${brand} esté tibia.<br/>
      <strong>8.</strong> Coloque la cuchara en su lugar original y cierre la tapa.<br/>
      <strong>9.</strong> Utilice la fórmula reconstituida dentro de 1 hora, como máximo, después de  su preparación.<br/>
      <strong>10.</strong> Deseche cualquier toma no consumida.
    </div>
    <div class="warning">
      Atención:<br/>
      Use la fórmula ${brand} como máximo dentro de una hora después de su preparación.
    </div>`;
};

const medalsPaths = {
  dhaAa: "/img/products/medals/dha-aa.png",
  easyDigestion: "/img/products/medals/easy-digestion.png",
  gos: "/img/products/medals/gos.png",
  hidroProtein: "/img/products/medals/hidro-protein.png",
  hmo: "/img/products/medals/hmo.png",
  noFrizer: "/img/products/medals/no-frizer.png",
  probiotics: "/img/products/medals/probiotics.png",
};

const products: Product[] = [
  {
    slug: "frisolac-gold-1",
    type: "regular",
    color: "#00468f",
    isNew: false,
    text: {
      name: "Frisolac Gold 1",
      brand: "Frisolac<sup>®</sup> Gold 1",
      description: "Proteína de fácil digestión",
      age: "De 0 a 6 meses",
      body: "<ul><li>Frisolac Gold 1 tiene baja glicación de las proteínas: el procesamiento mínimo limita la glicación de las proteínas, mostrando un efecto positivo en la digestión</li><li>Frisolac Gold 1 tiene niveles bajos de mineralización de la caseína: se sabe que forma partículas de cuajo más suaves en el estómago, que son más fáciles de digerir</li><li>Evidencia científica ha mostrado que los prebióticos 2’-FL (HMO) y GOS estimulan el crecimiento de bacterias benéficas y ayudan a mantener la salud intestinal</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li></ul>",
      ingredients: "Sólidos de la leche (Leche descremada, Suero de leche desmineralizado, Lactosa, Concentrado de proteína de suero de leche), Aceite de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erútico (<i>Brassica napus</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Galacto&#8209;oligosacáridos, Maltodextrina, Citrato de potásico, Carbonato de calcio, Aceite de pescado, Bitartrato de colina, Oligosacárido (2’&#8209;fucosil&#8209;lactosa), Cloruro magnésico, Aceite unicelular (<i>Mortierella alpina</i>), L&#8209;Ascorbato sódico, Citrato sódico, Fosfato cálcico tribásico, Cloruro sódico, Cloruro potásico, Taurina, Sulfato ferroso, Hidróxido de cálcico, Emulsificante (Lecticina de girasol (<i>Helianthus annuus</i>)), Mioinositol, L&#8209;carnitina, DL&#8209;alfa tocoferil acetato, Sulfato de zinc, Uridina 5&#8209;monofosfato disódica, Antioxidante (Palmitato de ascorbilo), Ácido citidina 5&#8209;monofosfato, D&#8209;pantotenato cálcico, Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Nicotinamida, Guanosina 5&#8209;monofosfato disódica, Sulfato cúprico, Acetato de retinilo, Hidrocloruro de tiamina, Hidrocloruro de piridoxina, Beta caroteno, Sulfato de manganeso II, Ácido fólico, Yoduro potásico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina. <b>Contiene: Leche, lactosa y productos derivados del pescado.</b>",
      preparation: getPreparation(`Frisolac<sup>®</sup> Gold 1`, `/img/products/preparation-table-2.png`, `4,3`),
    },
    image: {
      packshot: "/img/products/packshots/frisolac-gold-1.png",
      packshotThumb: "/img/products/packshots/frisolac-gold-1--thumb.png",
      table: "/img/products/tables/frisolac-gold-1.svg",
    },
    medals: [
      medalsPaths.easyDigestion,
      medalsPaths.hmo,
      medalsPaths.gos,
      medalsPaths.dhaAa,
    ]
  },
  {
    slug: "frisolac-gold-2",
    type: "regular",
    color: "#a2c035",
    isNew: false,
    text: {
      name: "Frisolac Gold 2",
      brand: "Frisolac<sup>®</sup> Gold 2",
      description: "Proteína de fácil digestión",
      age: "De 6 a 12 meses",
      body: "<ul><li>Frisolac Gold 2 tiene baja glicación de las proteínas: el procesamiento mínimo limita la glicación de las proteínas, mostrando un efecto positivo en la digestión</li><li>Frisolac Gold 2 tiene niveles bajos de mineralización de la caseína: se sabe que forma partículas de cuajo más suaves en el estómago, que son más fáciles de digerir</li><li>Evidencia científica ha mostrado que los prebióticos 2’-FL (HMO) y GOS estimulan el crecimiento de bacterias benéficas y ayudan a mantener la salud intestinal</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li></ul>",
      ingredients: "Sólidos de la leche (Leche descremada, Suero de leche desmineralizado, Lactosa, Concentrado de proteína de suero de leche), Aceite de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erútico (<i>Brassica napus</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Galacto&#8209;oligosacáridos, Sólidos de jarabe de glucosa, Citrato potásico, Carbonato de calcio, Fosfato cálcico di&#8209;y tribásico, Oligosacárido (2’&#8209;fucosil&#8209;lactosa), Cloruro magnésico, L&#8209;Ascorbato sódico, Aceite de pescado, Citrato sódico, Bitartrato de colina, Cloruro sódico, Cloruro potásico, Aceite unicelular (<i>Mortierella alpina</i>), Taurina, Sulfato ferroso, Hidróxido de cálcico, Emulsionante (Lecticina de girasol (<i>Helianthus annuus</i>)), Mioinositol, Sulfato de zinc, Uridina 5&#8209;monofosfato disódica, L&#8209;carnitina, DL&#8209;alfa tocoferil acetato, Ácido citidina 5&#8209;monofosfato, Nicotinamida, Antioxidante (Palmitato de ascorbilo), Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, D&#8209;pantotenato cálcico, Guanosina 5&#8209;monofosfato disódica, Sulfato cúprico, Hidroclururo de piridoxina, Hidrocloruro de tiamina, Sulfato de manganeso II, Acetato de retinilo, Beta caroteno, Ácido fólico, Riboflavina, Yoduro potásico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina. <b>Contiene: Leche, lactosa y productos derivados del pescado.</b>",
      preparation: getPreparation(`Frisolac<sup>®</sup> Gold 2`, `/img/products/preparation-table-4.png`, `5,0`),
    },
    image: {
      packshot: "/img/products/packshots/frisolac-gold-2.png",
      packshotThumb: "/img/products/packshots/frisolac-gold-2--thumb.png",
      table: "/img/products/tables/frisolac-gold-2.svg",
    },
    medals: [
      medalsPaths.easyDigestion,
      medalsPaths.hmo,
      medalsPaths.gos,
      medalsPaths.dhaAa,
    ]
  },
  {
    slug: "friso-gold-3",
    type: "regular",
    color: "#d76212",
    isNew: false,
    text: {
      name: "Friso Gold 3",
      brand: "Friso<sup>®</sup> Gold 3",
      description: "Proteína de fácil digestión",
      age: "De 1 a 3 años",
      body: "<ul><li>Friso Gold 3 tiene baja glicación de las proteínas: el procesamiento mínimo limita la glicación de las proteínas, mostrando un efecto positivo en la digestión</li><li>Friso Gold 3 tiene niveles bajos de mineralización de la caseína: se sabe que forma partículas de cuajo más suaves en el estómago, que son más fáciles de digerir</li><li>Evidencia científica ha mostrado que los prebióticos 2'-FL (HMO) y GOS estimulan el crecimiento de bacterias benéficas y ayudan a mantener la salud intestinal</li><li>Probiótico: <i>B. lactis HN019</i> apoya un sistema digestivo sano.</li><li>Friso Gold 3 no contiene sacarosa</li></ul>",
      ingredients: "Sólidos de la leche (Leche descremada, Suero de leche desmineralizado, Lactosa, Concentrado de proteína de suero de leche), Aceite de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erútico (<i>Brassica napus</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Galacto&#8209;oligosacáridos, Cloruro potásico, Maltodextrina, Fosfato cálcico tribásico, Citrato potásico, Oligosacárido (2’&#8209;fucosil&#8209;lactosa), L&#8209;Ascorbato sódico, Aceite de pescado, Mioinositol, Cloruro de colina, Aceite unicelular (<i>Mortierella alpina</i>), Taurina, Sulfato ferroso, Saborizantes de Vainilla, Emulsionante (Lecitina de girasol (<i>Helianthus annuus</i>)), Antioxidante (Mezcla de tocoferoles concentrado), Uridina 5&#8209;monofosfato disódica, Sulfato de zinc, Palmitato de ascorbilo, Ácido citidina 5&#8209;monofosfato, Bifidobacterium lactis HN019, Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Nicotinamida, DL&#8209;alfa tocoferil acetato, Guanosina 5&#8209;monofosfato disódica, D&#8209;pantotenato cálcico, Sulfato de manganeso II, Sulfato cúprico, Acetato de retinilo, Hidrocloruro de tiamina, Hidrocloruro de piridoxina, Ácido fólico, Fitomenadiona, Selenito sódico, Yoduro potásico, Colecalciferol, D&#8209;biotina, Cianobalamina. <b>Contiene: Leche, lactosa y productos derivados del pescado.</b>",
      preparation: getPreparation(`Friso<sup>®</sup> Gold 3`, undefined, undefined, `
        <img class="table" src="/img/products/preparation-table-6.png"/>
        <p>
          El uso de agua sin hervir, taza o recipientes no esterilizados o la preparación incorrecta del producto puede ser perjudicial para su niño.
        </p>
        <img class="table" src="/img/products/preparation-table-7.png"/>
      `),
    },
    image: {
      packshot: "/img/products/packshots/friso-gold-3.png",
      packshotThumb: "/img/products/packshots/friso-gold-3--thumb.png",
      table: "/img/products/tables/friso-gold-3.svg",
    },
    medals: [
      medalsPaths.easyDigestion,
      medalsPaths.hmo,
      medalsPaths.gos,
      medalsPaths.noFrizer,
      medalsPaths.probiotics,
    ]
  },
  {
    slug: "frisolac-gold-comfort",
    type: "special",
    color: "#a2c035",
    isNew: false,
    text: {
      name: "Frisolac Gold Comfort",
      brand: "Frisolac<sup>®</sup> Gold Comfort",
      description: "Manejo dietético del Reflujo Gastroesofágico, heces duras, estreñimiento y cólico",
      age: "De 0 a 12 meses",
      body: "<ul><li>Frisolac Gold Comfort contiene la cantidad óptima de goma de algarrobo que ha probado ser efectiva para reducir los índices de reflujo</li><li>Los prebióticos GOS apoyan una microbiota intestinal sana</li><li>La proteína parcialmente hidrolizada de suero acelera la tasa de vaciamiento gástrico</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li><li>Lactosa completa: el carbohidrato óptimo para los lactantes</li></ul>",
      ingredients: "Sólidos de la leche (Leche descremada, Suero de leche desmineralizado, Lactosa, Concentrado de proteína de suero de leche), Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erúcico (<i>Brassica napus</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Proteína de suero de leche hidrolizada, Goma de algaborro, Maltodextrina, Galacto&#8209;ologosacáridos, Fosfato cálcico di&#8209;y tribásico, Emulsionante (Ésteres mono y diglicéridos de ácido cítrico), Aceite de pescado, Citrato potásico, Carbonato de calcio, Cloruro de colina, Cloruro sódico, Aceite unicelular (<i>Mortierella alpina</i>), Sales magnésicas de ácido cítrico, Cloruro potásico, L&#8209;Ascorbato sódico, Cloruro magnésico, Mioinositol, Taurina, Sulfato ferroso, Emulsionante (Lecitina de girasol (Helianthus annus)), Hidróxido de cálcico, L&#8209;fenilalanina, L&#8209;tirosina, Sulfato de zinc, Uridina 5&#8209;monofosfato disódica, Palmitato de ascorbilo, D&#8209;pantotenato cálcico, Antioxidante (Mezcla tocoferoles concentrados), Ácido citidina 5&#8209;monofosfato, DL&#8209;alfa tocoferil acetatp, Inosina 5&#8209;monofosfato disódica, Nicotinamida, Ácido adenosina 5&#8209;monofosfato, Tartrato de L&#8209;carnitina, Guanosina 5&#8209;monofosfato disódica, Sulfato cúprico, Hidrocloruro de tiamina, Riboflavina, Acetato de retinilo, Hidrocloruro de piridoxina, Sulfato de manganeso II, Ácido fólico, Yoduro potásico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina. <b>Contiene: Leche lactosa y productos derivados del pescado.</b>",
      preparation: getPreparation(`Frisolac<sup>®</sup> Gold Comfort`, `/img/products/preparation-table-1.png`, `4,3`),
    },
    image: {
      packshot: "/img/products/packshots/frisolac-gold-comfort.png",
      packshotThumb: "/img/products/packshots/frisolac-gold-comfort--thumb.png",
      table: "/img/products/tables/frisolac-gold-comfort.svg",
    },
    medals: [
      medalsPaths.gos,
      medalsPaths.dhaAa,
      medalsPaths.hidroProtein,
    ]
  },
  {
    slug: "friso-gold-comfort-next",
    type: "special",
    color: "#0f764c",
    isNew: true,
    text: {
      name: "Friso Gold Comfort Next",
      brand: "Friso<sup>®</sup> Gold Comfort Next",
      age: "De 12 a 36 meses",
      description: "Manejo dietético del estreñimiento y de las heces duras",
      body: "<div>Apoya el manejo dietético de los problemas digestivos ya que contiene Milk Lipid Complex, probióticos y una mezcla de fibras prebióticas:</div><ul><li>Milk Lipid Complex: Asociado con la formación de heces más suaves</li><li>Goma de algarrobo: Mejora la microbiota y apoya la motilidad intestinal</li><li>GOS: Mejora la microbiota intestinal y la consistencia de las heces</li><li>Inulina: Estimula el crecimiento de las Bifidobacterias y la producción de heces más suaves</li><li>B. lactis HN019: Reduce la frecuencia del estreñimiento, los movimientos intestinales y las flatulencias</li><li>Friso Gold Comfort Next no contiene azúcares añadidos</li></ul>",
      ingredients: "Ingredientes: Sólidos de la leche (Leche descremada, Lactosa, Suero de leche desmineralizado), Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>), Aceite de canola de bajo contenido de ácido erúcico (<i>Brassica napus</i>), Aceite de almendra de palma (<i>Elaeis guineensis</i>), Aceite de coco (<i>Cocos nucifera</i>)), Leche entera, Grasa láctea, Galacto&#8209;oligosacáridos, Maltodextrina, Inulina, Goma de algarrobo, Fosfato cálcico dibásico, Bitartrato de colina, Aceite de pescado, Citrato potásico, L&#8209;Ascorbato sódico, Fosfato magnésico dibásico, Carbonato de calcio, Emulsionante (Lecitina de soya y Lecitina de girasol (<i>Helianthus annuus</i>)), Cloruro magnésico, Fosfato cálcico tribásico, Taurina, Nucleótidos (Citidina 5’&#8209; monofosfato, Uridina 5’&#8209;monofosfato disódica, Adenosina 5’&#8209;monofosfato, Inosina 5’&#8209;monofosfato disódica, Guanosina 5’&#8209;monofosfato disódica), Citrato sódico, Cloruro sódico, Saborizantes de Vainilla, Sulfato ferroso, Hidróxido de cálcico, DL&#8209;alfa tocoferil acetato, Sulfato de zinc, Mioinositol, Nicotinamida, Bifidobacterium lactis HN019, L&#8209;carnitina, Palmitato de ascorbilo, D&#8209;pantotenato cálcico, Antioxidante (Mezcla de tocoferoles concentrados), Sulfato cúprico, Hidrocloruro de tiamina, Hidrocloruro de piridoxina, Acetato de retinilo, Sulfato de manganeso II, Ácido fólico, Riboflavina, Selenito sódico, Yoduro potásico, Fitomenadiona, D-biotina, Colecalciferol, Cianocobalamina. Contiene: Leche, lactosa, y productos derivados del pescado y de la soya.",
      preparation: getPreparation(`Friso<sup>®</sup> Gold Comfort Next`, `/img/products/preparation-table-3.png`, `4,4`),
    },
    image: {
      packshot: "/img/products/packshots/friso-gold-comfort-next.png",
      packshotThumb: "/img/products/packshots/friso-gold-comfort-next--thumb.png",
      table: "/img/products/tables/friso-gold-comfort-next.svg",
    },
    medals: [
      medalsPaths.noFrizer,
      medalsPaths.dhaAa,
      medalsPaths.gos,
      medalsPaths.probiotics,
    ]
  },
  {
    slug: "frisolac-gold-prematuros",
    type: "special",
    color: "#ba296a",
    isNew: false,
    text: {
      name: "Frisolac Gold Prematuros",
      brand: "Frisolac<sup>®</sup> Gold Prematuros",
      description: "Del nacimiento en adelante, hasta alcanzar la edad o peso a término",
      age: "Lactantes prematuros y/o de bajo peso al nacer",
      body: "<ul><li>Proporciona un alto contenido de proteína, energía, micronutrientes y ácidos grasos poliinsaturados de cadena larga de acuerdo con las recomendaciones de ESPGHAN para lactantes prematuros</li><li>Relación suero/caseína, 80:20 (suero parcialmente hidrolizado y caseína extensamente hidrolizada)</li><li>Triglicéridos de cadena media: apoyan las necesidades de energía</li><li>Prebióticos GOS: beneficiosos para la microbiota intestinal</li></ul>",
      ingredients: "Sólidos de la leche (Lactosa, Proteína de suero de leche hidrolizada), Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erúcico (<i>Brassica napus</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de TCM (<i>Elaeis guineensis</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Maltodextrina, Caseína hidrolizada, Emulsionante (Ésteres mono y diglicéridos de ácido cítrico), Galacto&#8209;oligosacáridos, Fosfato cálcico di&#8209;y tribásico, Aceite de pescado, Aceite unicelular (<i>Mortierella alpina</i>), Carbonato de calcio, Cloruro magnésico, Cloruro sódico, L&#8209;Ascorbato sódico, Mioinositol, Cloruro de colina, Taurina, Sulfato ferroso, DL&#8209;alfa tocoferil acetato, Sulfato de zinc, Nicotinamida, Emulsionante (Lecitina de girasol (<i>Helianthus annuus</i>)), L&#8209;carnitina, Ácido citidina 5&#8209;monofosfato, Palmitato de ascorbilo, D&#8209;pantotenato cálcico, Uridina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Guanosina 5&#8209;monofosfato disódica, Acetato de retinilo, Inosina 5&#8209;monofosfato disódica, Sulfato cúprico, Riboflavina, Hidrocloruro de tiamina, Hidrocloruro de piridoxina, Ácido fólico, Yoduro potásico, Fitomenadiona, Colecalciferol, Selenito sódico, D&#8209;biotina, Sulfato de manganeso II, Cianocobalamina. <b>Contiene: Leche, lactosa y productos derivados del pescado.</b>",
      preparation: getPreparation(`Frisolac<sup>®</sup> Gold Prematuros`, undefined, undefined, `
        <p>
          La composición de Frisolac<sup>®</sup> Gold Prematuros está basada en un consumo de 150 ml de fórmula preparada por kg de peso por día.
        </p>
        <img class="table" src="/img/products/preparation-table-5.png"/>
        <p>
          <strong>1 cucharada nivelada es de aprox. 5,2 gramos.</strong>
        </p>
      `),
    },
    image: {
      packshot: "/img/products/packshots/frisolac-gold-prematuros.png",
      packshotThumb: "/img/products/packshots/frisolac-gold-prematuros--thumb.png",
      table: "/img/products/tables/frisolac-gold-prematuros.svg",
    },
    medals: [
      medalsPaths.gos,
      medalsPaths.dhaAa,
      medalsPaths.hidroProtein,
    ]
  },
  {
    slug: "frisolac-gold-sin-lactosa",
    type: "special",
    color: "#fab842",
    isNew: false,
    text: {
      name: "Frisolac Gold Sin Lactosa",
      brand: "Frisolac<sup>®</sup> Gold Sin Lactosa",
      description: "Manejo dietético de la intolerancia a la lactosa y de la diarrea",
      age: "De 0 a 12 meses",
      body: "<ul><li>Triglicéridos de cadena media: proporcionan una fuente de energía fácilmente absorbible sin añadir estrés al intestino</li><li>Contenido óptimo de hierro: asegura el crecimiento adecuado del lactante sin aumentar el riesgo de prolongar la diarrea infecciosa.</li><li>Zinc: reduce la severidad y la duración de la diarrea y permite una mejor eliminación de patógenos</li><li>Almidón: ha mostrado reducir el fracaso de la terapia de rehidratación</li><li>Inulina: apoya el sistema digestivo y la formación de heces</li></ul>",
      ingredients: "Jarabe de glucosa, Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de triglicéridos de cadena media (<i>Elaeis guineensis</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erútico (<i>Brassica napus</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Concentrado de proteína de suero de leche, Almidón (papa), Caseinato de sodio, Fructo&#8209;oligosacáridos (inulina), Cloruro potásico, Fosfato cálcico tribásico, Bitartrato de colina, Fosfato magnésico dibásico, Carbonato de calcio, Citrato sódico, Aceite de pescado, Citrato potásico, Aceite unicelular (<i>Mortierella alpina</i>), L&#8209;Ascorbato sódico, Leche descremada, Taurina, Mioinositol, Emulsionante (Lecitina de girasol (<i>Helianthus annuus</i>)), Sulfato de zinc, Sulfato ferroso, Tartrato de L&#8209;carnitina, Palmitato de ascorbilo, Uridina 5&#8209;monofosfato disódica, D&#8209;pantotenato cálcico, DL&#8209;alfa tocoferil acetato, Nicotinamida, Ácido citidina 5&#8209;monofosfato, Antioxidante (Mezcla de tocoferoles concentrado), Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Guanosina 5&#8209;monofosfato disódica, Sulfato cúprico, Riboflavina, Hidrocloruro de tiamina, Acetato de retinilo, Hidrocloruro de piridoxina, Sulfato de manganeso II, Yoduro potásico, Ácido fólico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina. <b>Contiene: Leche y productos derivados del pescado.</b>",
      preparation: getPreparation(`Frisolac<sup>®</sup> Gold Sin Lactosa`, `/img/products/preparation-table-1.png`, `4,3`),
    },
    image: {
      packshot: "/img/products/packshots/frisolac-gold-sin-lactosa.png",
      packshotThumb: "/img/products/packshots/frisolac-gold-sin-lactosa--thumb.png",
      table: "/img/products/tables/frisolac-gold-sin-lactosa.svg",
    }
  },
  {
    slug: "frisolac-gold-hipoalergenico",
    type: "special",
    color: "#4e7bbc",
    isNew: false,
    text: {
      name: "Frisolac Gold Hipoalergénico",
      brand: "Frisolac<sup>®</sup> Gold Hipoalergénico",
      description: "Ayuda a reducir el riesgo de desarrollar alergia a la proteína de la leche de vaca en lactantes con riesgo incrementado, con base en la historia familiar de alergia",
      age: "De 0 a 12 meses",
      body: "<ul><li>Estimula la reacción normal del sistema inmune a las proteínas inocuas al contener proteína de suero parcialmente hidrolizada con cadenas de péptidos más largas</li><li>Hecho a base de proteína parcialmente hidrolizada de suero, que se sabe reduce la inducción a la sensibilización de la leche de vaca</li><li>Los prebióticos GOS apoyan una microbiota intestinal sana</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li><li>Los nucleótidos estimulan el desarrollo del sistema inmune</li></ul>",
      ingredients: "Lactosa, Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erúcico (<i>Brassica napus</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Proteína de suero de leche hidrolizada, Maltodextrina, Galacto&#8209;oligosacáridos, Emulsionante (Ésteres mono y diglicéridos de ácido cítrico), Fosfato cálcico di&#8209;y tribásico, Cloruro magnésico, Cloruro de colina, Aceite de pescado, Aceite unicelular (<i>Mortierella alpina</i>), Mioinositol, L&#8209;Ascorbato sódico, Cloruro potásico, L&#8209;fenilalanina, L&#8209;tirosina, Taurina, Cloruro sódico, Sulfato ferroso, Tartrato de L&#8209;carnitina, Emulsionante (Lecitina de girasol (<i>Helianthus annuus</i>)), Sulfato de zinc, Palmitato de ascorbilo, Uridina 5&#8209;monofosfato disódica, D&#8209;pantotenato cálcico, Antioxidante (Mezcla tocoferoles concentrados), Ácido citidina 5&#8209;monofosfato, DL&#8209;alfa tocoferil acetato, Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Nicotinamida, Guanosina 5&#8209;monofosfato disódica, Sulfato cúprico, Hidrocloruro de tiamina, Riboflavina, Acetato de retinilo, Hidrocloruro de piridoxina, Sulfato de manganeso II, Yoduro potásico, Ácido fólico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina. <b>Contiene: Lactosa y productos derivados del pescado.</b>",
      preparation: getPreparation(`Frisolac<sup>®</sup> Gold Hipoalergénico`, `/img/products/preparation-table-1.png`, `4,3`),
    },
    image: {
      packshot: "/img/products/packshots/frisolac-gold-hipoalergenico.png",
      packshotThumb: "/img/products/packshots/frisolac-gold-hipoalergenico--thumb.png",
      table: "/img/products/tables/frisolac-gold-hipoalergenico.svg",
    },
    medals: [
      medalsPaths.hidroProtein,
      medalsPaths.gos,
      medalsPaths.dhaAa,
    ]
  },
  {
    slug: "frisolac-gold-intensive-ha",
    type: "special",
    color: "#8684be",
    isNew: false,
    text: {
      name: "Frisolac Gold Intensive HA",
      brand: "Frisolac<sup>®</sup> Gold Intensive HA",
      description: "Tratamiento dietético de síntomas moderados de alergia a la proteína de la leche de vaca",
      age: "De 0 a 12 meses",
      body: "<ul><li>Hecho a base de proteína extensamente hidrolizada de suero. 80% ≤ 1.5 Kda</li><li>Se asocia con una reducción de los niveles de IgE y IgE específica para la alergia a la proteína de la leche de vaca</li><li>Tiene buena efectividad terapéutica en lactantes con síntomas moderados de dermatitis atópica y fue bien tolerada</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li><li>50% de lactosa</li></ul>",
      ingredients: "Sólidos de jarabe de glucosa, Lactosa, Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erúcico (<i>Brassica napus</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Proteína de suero de leche hidrolizada, Galacto&#8209;oligosacáridos, Emulsionante (Ésteres mono y diglicéridos de ácido cítrico), Fosfato cálcico di&#8209;y tribásico, Cloruro magnésico, Cloruro potásico, Cloruro de colina, Citrato sódico, Emulsionante (Almidón octenil succinato sódico), Cloruro sódico, Aceite de DHA de microalgas (Crypthecodinium cohnii), L&#8209;Ascorbato sódico, Aceite unicelular (<i>Mortierella alpina</i>), L&#8209;tirosina, Mioinositol, Citrato potásico, L&#8209;fenilalanina, Taurina, Sulfato ferroso, Emulsionante (Lecitina de girasol (<i>Helianthus annuus</i>)), Tartrato de L&#8209;carnitina, Sulfato de zinc, Emulsionante (Mono&#8209;y diglicéridos de ácidos grasos), Uridina 5&#8209;monofosfato disódica, D&#8209;pantotenato cálcico, Palmitato de ascorbilo, Antioxidante (Mezcla tocoferoles concentrados), Ácido citidina 5&#8209;monofosfato, DL&#8209;alfa tocoferil acetato, Nicotinamida, Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Guanosina 5&#8209;monofosfato disódica, Sulfato cúprico, Hidrocloruro de tiamina, Riboflavina, Hidrocloruro de piridoxina, Acetato de retinilo, Sulfato de manganeso II, Ácido fólico, Yoduro potásico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina. <b>Contiene: Lactosa.</b>",
      preparation: getPreparation(`Frisolac<sup>®</sup> Gold Intensive HA`, `/img/products/preparation-table-1.png`, `4,3`),
    },
    image: {
      packshot: "/img/products/packshots/frisolac-gold-intensive-ha.png",
      packshotThumb: "/img/products/packshots/frisolac-gold-intensive-ha--thumb.png",
      table: "/img/products/tables/frisolac-gold-intensive-ha.svg",
    },
    medals: [
      medalsPaths.hidroProtein,
      medalsPaths.gos,
      medalsPaths.dhaAa,
    ]
  },
  {
    slug: "frisolac-gold-pep-ac",
    type: "special",
    color: "#5d519d",
    isNew: false,
    text: {
      name: "Frisolac Gold Pep AC",
      brand: "Frisolac<sup>®</sup> Gold Pep AC",
      description: "Tratamiento dietético de síntomas severos de alergia a la proteína de la leche de vaca",
      age: "De 0 a 12 meses",
      body: "<ul><li>Hecho a base de proteína extensamente hidrolizada de caseína. 98% ≤ 1.5 Kda</li><li>Libre de péptidos estimulantes del sistema inmune y es tan eficaz como las fórmulas de aminoácidos libres</li><li>No induce actividad alergénica por proliferación de células T ni por liberación de citoquinas proinflamatorias</li><li>DHA y ARA apoyan el aprendizaje y el desarrollo cognitivo</li><li>Libre de lactosa</li></ul>",
      ingredients: "Sólidos de jarabe de glucosa, Aceites vegetales (Aceite de palma (<i>Elaeis guineensis</i>), Aceite de semilla de palma (<i>Elaeis guineensis</i>), Aceite de canola de bajo contenido de ácido erúcico (<i>Brassica napus</i>), Aceite de girasol (<i>Helianthus annuus</i>)), Caseína hidrolizada, Emulsionante (Ésteres mono y diglicéridos de ácido cítrico), Fosfato cálcico di&#8209;y tribásico, Cloruro potásico, Cloruro magnésico, Carbonato de calcio, Cloruro de colina, L&#8209;cistina, Emulsionante (Almidón octenil succinato sódico), L&#8209;triptófano, Aceite de DHA de microalgas (Crypthecodinium cohnii), Aceite unicelular (<i>Mortierella alpina</i>), Mioinositol, L&#8209;Ascorbato sódico, Taurina, Sulfato ferroso, Tartrato de L&#8209;carnitina, Sulfato de zinc, Emulsionante (Mono&#8209;y diglicéridos de ácidos grasos), D&#8209;pantotenato cálcico, Uridina 5&#8209;monofosfato disódica, DL&#8209;alfa tocoferil acetato, Palmitato de ascorbilo, Antioxidante (Mezcla tocoferoles concentrados), Ácido citidina 5&#8209;monofosfato, Inosina 5&#8209;monofosfato disódica, Ácido adenosina 5&#8209;monofosfato, Nicotinamida, Guanosina 5&#8209;monofosfato disódica, Hidrocloruro de tiamina, Riboflavina, Sulfato cúprico, Hidrocloruro de piridoxina, Acetato de retinilo, Sulfato de manganeso II, Yoduro potásico, Ácido fólico, Fitomenadiona, Selenito sódico, D&#8209;biotina, Colecalciferol, Cianocobalamina.",
      preparation: getPreparation(`Frisolac<sup>®</sup> Gold Pep AC`, `/img/products/preparation-table-1.png`, `4,3`),
    },
    image: {
      packshot: "/img/products/packshots/frisolac-gold-pep-ac.png",
      packshotThumb: "/img/products/packshots/frisolac-gold-pep-ac--thumb.png",
      table: "/img/products/tables/frisolac-gold-pep-ac.svg",
    },
    medals: [
      medalsPaths.hidroProtein,
      medalsPaths.dhaAa,
    ]
  },
];

function getProducts(): Product[] {

  return products;
}

function getProduct(slug: string) {
  return products.find(prod => prod.slug === slug);
}

export { getProducts, getProduct };