<template>
  <section class="breastfeeding-banner py-8 bg-white">
    <div class="container text-center">
      <div class="font-display font-bold text-2xl text-primary leading-6 mb-2">
        La lactancia materna es el mejor alimento para el sano crecimiento y desarrollo del lactante.
      </div>
      <div class="text-primary leading-4">
        Cuando la lactancia materna no es una opción, nuestra gama de productos presenta
        soluciones para cada bebé, con o sin necesidades especiales.
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.breastfeeding-banner {
  box-shadow: inset 0px 12px 12px -8px rgba(0,0,0,0.2);
  background-image: url('/img/breastfeeding-warning-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>